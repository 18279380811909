import { useState } from 'react';
import TableHeader from './TableHeader';
import PropertyRecords from './PropertyRecords';
import Pagination from '../Pagination';
import AddNewPropertyModal from '../Modals/AddNewPropertyModal';
import AddNewCatalogModal from '../Modals/AddNewCatalogModal';
import AddNewInspectionReportModal from '../Modals/AddNewInspectionReportModal';
import AddNewPropertyReportModal from '../Modals/AddNewPropertyReportModal';
import AddNewPropertyImageModal from '../Modals/AddNewPropertyImageModal';

function PropertiesTable({ token, propertiesData, name, organizationId, totalRecords, dataUpdate, setDataUpdate }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(15);
    const [activePropertyModal, setActivePropertyModal] = useState(false);
    const [activeCatalogModal, setActiveCatalogModal] = useState(false);
    const [activeInspectionModal, setActiveInspectionModal] = useState(false);
    const [activePhotoModal, setActivePhotoModal] = useState(false);
    const [activePropertyReportModal, setActivePropertyReportModal] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState('');

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const firstRecord = indexOfFirstRecord + 1
    const lastRecord = (totalRecords < indexOfLastRecord) ? totalRecords : indexOfLastRecord
    
    const currentRecords = propertiesData.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(propertiesData.length / recordsPerPage)

    return (
        <div className="w-11/12">
            <div className="w-full flex gap-3 justify-end">
                <button onClick={() => setActivePropertyReportModal(true)} className="light-blue-button hover:bg-cyan-400">
                    <p>Add Inspection Report</p>
                </button>
                <button onClick={() => setActivePropertyModal(true)} className="light-blue-button hover:bg-cyan-400">
                    <p>Add Property</p>
                </button>
            </div>
            <h1>Showing data for {name}</h1>
            <div>
                <TableHeader totalRecords={totalRecords} />
                <PropertyRecords 
                    token={token}
                    data={currentRecords} 
                    dataUpdate={dataUpdate}
                    setDataUpdate={setDataUpdate}
                    activeCatalogModal={activeCatalogModal}
                    setActiveCatalogModal={setActiveCatalogModal}
                    activeInspectionModal={activeInspectionModal}
                    setActiveInspectionModal={setActiveInspectionModal}
                    activePhotoModal={activePhotoModal}
                    setActivePhotoModal={setActivePhotoModal}
                    setSelectedProperty={setSelectedProperty}
                />
                <Pagination 
                    page="Properties"
                    nPages={nPages}
                    firstRecord={firstRecord}
                    lastRecord={lastRecord}
                    currentPage={currentPage}
                    totalRecords={totalRecords}
                    setRecordsPerPage={setRecordsPerPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
            <AddNewPropertyModal
                organizationId={organizationId}
                dataUpdate={dataUpdate}
                activePropertyModal={activePropertyModal}
                setDataUpdate={setDataUpdate} 
                setActivePropertyModal={setActivePropertyModal}
            />
            <AddNewCatalogModal 
                selectedProperty={selectedProperty}
                dataUpdate={dataUpdate}
                setDataUpdate={setDataUpdate}
                activeCatalogModal={activeCatalogModal}
                setActiveCatalogModal={setActiveCatalogModal}
                />
            <AddNewInspectionReportModal 
                selectedProperty={selectedProperty}
                dataUpdate={dataUpdate}
                setDataUpdate={setDataUpdate}
                activeInspectionModal={activeInspectionModal}
                setActiveInspectionModal={setActiveInspectionModal}
            />
            <AddNewPropertyImageModal 
                selectedProperty={selectedProperty}
                dataUpdate={dataUpdate}
                setDataUpdate={setDataUpdate}
                activePhotoModal={activePhotoModal}
                setActivePhotoModal={setActivePhotoModal}
            />
            <AddNewPropertyReportModal 
                organizationId={organizationId}
                dataUpdate={dataUpdate}
                setDataUpdate={setDataUpdate}
                activePropertyReportModal={activePropertyReportModal}
                setActivePropertyReportModal={setActivePropertyReportModal}
            />
        </div>
    )
}

export default PropertiesTable