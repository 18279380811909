import { useState } from "react";
import AddNewPropertyModal from "../Modals/AddNewPropertyModal";

import house_group from '../../static/images/house_group.png';

function NewProperty({ organizationId, dataUpdate, setDataUpdate }) {
    const [activePropertyModal, setActivePropertyModal] = useState(false);

    return (
        <div className="w-full flex justify-center items-center">
            <div className="bg-gray-100 flex flex-col items-center gap-8 pt-10 px-20 pb-20 rounded-md border-dotted border-2 border-gray-400 w-3/4 md:w-2/3 lg:w-1/2">
                <div className="flex flex-col items-center gap-3">
                    <img className="w-5/6" src={house_group} alt="" />
                    <p className="text-center caption-text font-bold">This organization doesn't have any properties</p>
                    <p className="text-center caption-text">No problem! Let's start onboarding the first property:</p>
                </div>
                <button onClick={() => setActivePropertyModal(true)} className="bg-blue-900 py-3 px-5 rounded-full text-white button-text hover:bg-blue-700">+ Onboard property</button>
                <AddNewPropertyModal 
                    activePropertyModal={activePropertyModal}
                    setActivePropertyModal={setActivePropertyModal}
                    organizationId={organizationId}
                    dataUpdate={dataUpdate}
                    setDataUpdate={setDataUpdate}
                />
            </div>
        </div>
    )
}

export default NewProperty